/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg';

import Steps from "widgets/Steps/Steps";
import Footer from "widgets/Footer/Footer";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import {
	useCustomerJourneyMutationQuery,
	useJourneyQuery,
	useJourneyResultFormQuery
} from 'entities/ApplicationForm/hooks/queries';
import { useFormBackMutationQuery } from 'entities/ApplicationFormBack/hooks/queries';
import { useProcessMutationQuery } from 'entities/ApplicationProcess/hooks/queries';

import { NavigationBundle, NavigationContext } from 'app/providers/router/ui/NavigationContext';
import { useQuoteQuery } from 'entities/Quote/hooks/queries';
import { useResolutionMutationQuery } from 'entities/Resolution/hooks/queries';
import { useUwQuestionsMutationQuery, useUwQuestionsQuery } from 'entities/UwQuestions/hooks/queries';
import { useAuthMutationQuery } from 'entities/Auth/hooks/queries';
import Loader from '../../shared/ui/Loader/Loader';

const HomePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname, search } = location;
	const {
		setPolicyStartDate,
		setPolicyState,
		setSchemaAddress,
		formId,
	} = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);
	const { mutateAsync: fetchForm, isSuccess, isError, isPending } = useCustomerJourneyMutationQuery();
	const { mutateAsync: getToken} = useAuthMutationQuery();
	const { mutate: backButton, isSuccess: backSuccess } = useFormBackMutationQuery();
	const { mutate: getApplicationProcess} = useProcessMutationQuery();
	const { mutate: setAnswers } = useResolutionMutationQuery();
	const { mutate: getUwQuestions, isPending: isQuestionsPending } = useUwQuestionsMutationQuery();
	const params = new URLSearchParams(search);
	const client_id: string = params.get('client_id') || localStorage.getItem('client_id') || "";
	const mainQuery = useJourneyQuery()

	const journeyResultData = useJourneyResultFormQuery();
	const queryQuote = useQuoteQuery();
	const queryQuestions = useUwQuestionsQuery();
	const [back, setBack] = useState(false);
	const [outcomeUrl, setOutcomeUrl] = useState();
	const { from, to } = useContext<NavigationBundle>(NavigationContext);

	useEffect(() => {
		if (queryQuote?.coveragesCue.businessRules && !queryQuestions && !isQuestionsPending) {
			const businessRules: Array<any> = queryQuote?.coveragesCue.businessRules;
			businessRules.map(item => {
				if (item.rule300KLiability) {
					const initialUrl = item.rule300KLiability.initialUrl;
					setOutcomeUrl(item.rule300KLiability.outcomeUrl)
					getUwQuestions(initialUrl);
				}
			});
		}
	}, [queryQuote]);
	useEffect(() => {
		if (outcomeUrl && queryQuestions && journeyResultData) {
			const answers: any = [];
			queryQuestions[0].questions.map((item: any) => {
				if (getQuestionAnswer(item)) {
					answers.push({
						QuestionId: item.questionId,
						QuestionOptionId: getQuestionAnswer(item),
					});
				}
			})
			const data = {
				QuestionnaireId: queryQuestions[0].questionnaireId,
				Answers: answers,
			};
			setAnswers({
				outcomeUrl,
				data
			});
		}
	}, [outcomeUrl, queryQuestions, journeyResultData]);

	const getQuestionAnswer = (question: any) => {
		if(!journeyResultData) return undefined;
		const schemaData = journeyResultData.underwriting.questions.reduce<Record<string, string>>((accum, item) => {
			accum[item.questionName] = item.answer
			return accum
		}, {})
		let questionOptionId;
		let value: string | undefined;

		switch (question.questionName) {
			case 'AnyPersonalLiabilityLossesInLast3Years-MI':
				value = schemaData.HaveYouHadAnyPersonalLiabilityLossesInThePast3Years;
				break;
			case 'DoYouOwnAnyAnimals':
				value = schemaData.DoYouOwnAnyAnimals;
				break;
			case 'Is300kRequiredByPmcOrForUmbrella-MI':
				value = schemaData.ThreeKLliabilityRequiredByLandlordManagementCompanyOrForAnUmbrellaPolicy;
				break;
			case 'IsLiabilityRequiredByLandLordOrPMC':
				value = schemaData.IsLiabilityRequiredByLandLordOrPMC;
				break;
			case 'DoYouOwnOrCareForAnyAnimals':
				value = schemaData.DoYouOwnOrCareForAnyAnimals;
				break;
			case 'AreDogsExcludedBreeds':
				value = schemaData.AreAnyOfTheDogsTheFollowingBreeds;
				break;
			case 'AreAnyDogsServiceAnimals':
				value = schemaData.AreAnyOfTheseDogsAServiceAnimal;
				break;
			case 'AnimalsCausedBodlyInjuredPastThreeYears':
				value = schemaData.OwnAnimalInThePropertyHarmedSomeone;
				break;
			case 'PropertyHavePool':
				value = schemaData.DoesThePropertyHaveAPool;
				break;
			case 'PoolScreenedFencedSecured':
				value = schemaData.IsThePoolScreenedFencedInAtLeast4FeetTallOrSecuredAgainstIntruders
				break;
			case 'LiabilityLosesInPastThreeYears':
				value = undefined;
				break;
			default:
				value = undefined;
				break;
		}
		question.options.map((option: any) => {
			if (option.optionValue === value) {
				questionOptionId = option.questionOptionId;
			}
		});
		return questionOptionId;
	};
	useEffect(() => {
		if((!client_id || isError)) {
			navigate("/error");
		}
	}, [isError])

	useEffect(() => {
		if (journeyResultData) {
			const policyStartDate = journeyResultData.quote_result.transactionDetails.policyEffectiveDate;
			const policyAddress = journeyResultData.property_address;

			if (policyStartDate) setPolicyStartDate(policyStartDate);
			if (policyAddress.state) {
				setPolicyState(policyAddress.state);
				setSchemaAddress({
					State: policyAddress.state,
					Address1: policyAddress.street1,
					Address2: policyAddress.street2,
					City: policyAddress.city,
					ZipCode: policyAddress.zip,
					FullAddress: '',
				});
			}
			setApplicationFormData(journeyResultData);
		}
	}, [journeyResultData, setApplicationFormData, setPolicyStartDate, setPolicyState, setSchemaAddress]);

	useEffect(() => {
		const token = localStorage.getItem('assurantJwt');
		const getData = async () => {
			try {
				await fetchForm(client_id);
				if(!token) {
					await getToken({customerJourneyId: client_id, productId: "", productCompanyId: "", productRequest: []})
				}
			} catch (e) {
				console.log('e: ', e);
			}
		}
		if (client_id) {
			getData().then(r=>r).catch(e => console.log('e: ', e))
		}
	}, [fetchForm, client_id])

	useEffect(() => {
		const applicationId = localStorage.getItem("client_id");
		if (backSuccess && applicationId && back) {
			getApplicationProcess(applicationId);
		}
	}, [backSuccess]);

	useEffect(() => {
		if (isSuccess && client_id ) {
			localStorage.setItem("client_id", client_id);
		}
	}, [isSuccess, client_id]);
	//
	// useEffect(() => {
	// 	if(moduleId || moduleId === null) {
	// 		localStorage.setItem("moduleId", moduleId as string);
	// 	}
	// }, [moduleId]);

	const goBack = () => {
		navigate(-1);
		setBack(true);
	}

	useEffect(() => {
		if (back) {
			if (formId) backButton(formId);
		}
	}, [back]);

	useEffect(() => {
		if (from?.pathname === "/checkout" && from.key !== "default" && to?.pathname === "/") {
			setBack(true);
		}
	}, [from, to]);

	useEffect(() => {
		if(mainQuery?.journeyStatus === 'issued') {
			window.location.href = 'https://renterscoverage.rein.ai/';
		}
	}, [mainQuery]);

	if(isPending) {
		return <Loader />
	}
	if (isError) {
		return <Navigate to="/error"/>;
	}
	return (
		<Box >
			{journeyResultData && !isPending && (
				<>
					<Grid
						container
						spacing={0}
						sx={{ minHeight: '100vh' }}
					>
						<Grid item xs={12}>
							<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5}>
								{pathname !== '/' && <Typography
									variant="body1"
									color={"text.secondary"}
									fontSize={14}
									sx={{ mr: 1, cursor: 'pointer', position: 'absolute' }}
									onClick={goBack}
								>
									Back
								</Typography>}
								<Logo width={171} height={40}/>
								<Typography variant="body1" fontSize="10px" lineHeight="12px">Renters Insurance powered by Assurant®</Typography>
							</Box>
							<Container maxWidth={"lg"} sx={{ mt: 5 }}>
								<Steps/>
								<Outlet/>
								<Footer/>
							</Container>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	)
}

export default HomePage
