/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import CheckoutForm from "widgets/CheckoutForm/CheckoutForm";
import CoverageOverview from "widgets/CoverageOverview/CoverageOverview";
import { StyledButton } from "shared/ui/Button/styles";
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { usePolicyMutationQuery } from 'entities/Policy/hooks/queries';
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import dayjs from 'dayjs';
import { SchemaAddress } from 'shared/api/assurant/quotePayloadTypes';
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import CheckoutErrorsInfo from 'widgets/CheckoutErrorsInfo/CheckoutErrorsInfo';
import { formatPhoneNumber } from 'shared/utils/helpers';
import { useJourneyResultFormQuery } from 'entities/ApplicationForm/hooks/queries';
import { JourneyResult } from '../../shared/config/types/customerResponse';

const CheckoutPage = () => {
	const { schemaAddress, setPaymentData, paymentData } = useContext(FormDataContext);
	const { applicationAddress, applicationFormData, setApplicationAddress } = useContext(ApplicationFormContext);
	const { isError } = usePolicyMutationQuery();
	const query = useJourneyResultFormQuery();

	const navigate = useNavigate();
	const isSmall = useMediaQuery('(max-width:600px)');
	const isMedium = useMediaQuery('(max-width:900px)');
	const methods = useForm<IFormICheckoutInput>({
		defaultValues: {
			firstName: '',
			lastName: '',
			fullAddress: '',
			fullName: '',
			dateOfBirth: '',
			email: '',
			phoneNumber: '',
			alerts: false,
			policy: false,
			communityName: '',
			propertyRadio: paymentData?.propertyRadio ?? 'sameAddress',
			billingAddressOption: paymentData?.billingAddressOption ?? 'useProperty',
			billingAddress: {
				address1: '',
				city: '',
				state: '',
				address2: '',
				lastName: '',
				zip: '',
				firstName: ''
			},
			paymentType: 'cc',
			payment: {
				cardName: '',
				cardCode: '',
				cardDate: '',
				cardNumber: '',
				bankAccountNumber: '',
				bankAccountNumConfirm: '',
				bankRoutingNumber: '',
			},
			mailingAddress: {
				address1: '',
				address2: '',
				city: '',
				state: '',
				postalCode: ''
			},
			mailingSave: '',
			billingSave: ''
		}
	});
	const { handleSubmit, setValue, getValues, watch, formState: { errors } } = methods;

	useEffect(() => {
		if (query) {
			const phoneNumber = query.insured_info.phone;
			setValue('phoneNumber', formatPhoneNumber(phoneNumber));
		}
	}, [query]);

	useEffect(() => {
		if (paymentData) {
			setValue("lastName", paymentData.lastName);
			setValue("phoneNumber", formatPhoneNumber(paymentData.phoneNumber));
			setValue("dateOfBirth", paymentData.dateOfBirth);
			setValue("email", paymentData.email);

			setValue('communityName', paymentData.communityName);
			setValue('alerts', paymentData.alerts);
			setValue('policy', paymentData.policy);

			setValue('payment.cardName', paymentData.payment.cardName);
			setValue('payment.cardNumber', paymentData.payment.cardNumber);
			setValue('payment.cardDate', paymentData.payment.cardDate);
			setValue('payment.cardCode', paymentData.payment.cardCode);
			setValue('payment.bankRoutingNumber', paymentData.payment.bankRoutingNumber);
			setValue('payment.bankAccountNumber', paymentData.payment.bankAccountNumber);
			setValue('payment.bankAccountNumConfirm', paymentData.payment.bankAccountNumConfirm);

			setValue('billingAddress.address1', paymentData.billingAddress.address1);
			setValue('billingAddress.address2', paymentData.billingAddress.address2);
			setValue('billingAddress.city', paymentData.billingAddress.city);
			setValue('billingAddress.state', paymentData.billingAddress.state);
			setValue('billingAddress.zip', paymentData.billingAddress.zip);
			setValue('billingAddress.firstName', paymentData.billingAddress.firstName);
			setValue('billingAddress.lastName', paymentData.billingAddress.lastName);

			setValue('mailingAddress.address1', paymentData.mailingAddress.address1);
			setValue('mailingAddress.address2', paymentData.mailingAddress.address2);
			setValue('mailingAddress.city', paymentData.mailingAddress.city);
			setValue('mailingAddress.state', paymentData.mailingAddress.state);
			setValue('mailingAddress.postalCode', paymentData.mailingAddress.postalCode);

			if (paymentData.additionalInsured) {
				setValue('additionalInsured.firstName', paymentData.additionalInsured.firstName);
				setValue('additionalInsured.lastName', paymentData.additionalInsured.lastName);
			}

			if (paymentData.interestedParty) {
				setValue('interestedParty.city', paymentData.interestedParty.city);
				setValue('interestedParty.app', paymentData.interestedParty.app);
				setValue('interestedParty.name', paymentData.interestedParty.name);
				setValue('interestedParty.state', paymentData.interestedParty.state);
				setValue('interestedParty.streetAddress', paymentData.interestedParty.streetAddress);
				setValue('interestedParty.zipcode', paymentData.interestedParty.zipcode);
			}

			setValue('paymentType', paymentData.paymentType);

			setValue('propertyRadio', paymentData.propertyRadio);

			if (paymentData.billingAddressOption === 'useDifferentBilling') {
				setValue('billingAddressOption', paymentData.billingAddressOption);
			}
			if (paymentData.roommates) {
				setValue('roommates', paymentData.roommates);
			}
			if (paymentData.authorizedUser) {
				setValue('authorizedUser.authorizedUserFirstName', paymentData.authorizedUser.authorizedUserFirstName);
				setValue('authorizedUser.authorizedUserLastName', paymentData.authorizedUser.authorizedUserLastName);
			}
		}
	}, []);

	const firstName = watch('firstName');
	const lastName = watch('lastName');
	const fullName = watch('fullName');

	useEffect(() => {
		processApplicationData(applicationFormData);
	}, [applicationFormData])

	useEffect(() => {
		const full = String(firstName) + ' ' + String(lastName);

		if (fullName.length !== full.length) {
			setValue("fullName", full);
		}
	}, [firstName, lastName, fullName, setValue, getValues])

	const processApplicationData = (applicationFormData: JourneyResult | null) => {
		// const personKeys = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.includes('Person'));
		// const contactKeys = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.includes('ContactInformation'));

		if (schemaAddress) {
			const street = schemaAddress.Address2 ? `${schemaAddress.Address1}, ${schemaAddress.Address2}` : schemaAddress.Address1;
			const userAddress = `${street}, ${schemaAddress.City}, ${schemaAddress.State}  ${schemaAddress.ZipCode}`;
			if (userAddress) {
				updatePropertyAddress(schemaAddress);
				setValue("fullAddress", userAddress);
			}
		}
		if (!applicationFormData) {
			return null
		}
		const personData = applicationFormData.insured_info
		if (personData) {

			const userName = `${personData.first_name} ${personData.last_name}`;
			const userBirthDate = personData.dob;

			if (userName) {
				const firstName = personData.first_name;
				const lastName = personData.last_name;

				setValue("fullName", userName);
				setValue("firstName", firstName);
				setValue("lastName", lastName);
			}
			if (userBirthDate) {
				const date = dayjs(userBirthDate).toDate();
				setValue("dateOfBirth", date);
			}
		}
		const userEmail = personData.email;
		if (userEmail) {
			setValue("email", userEmail);
		}
	};

	const updatePropertyAddress = (data: SchemaAddress) => {
		setApplicationAddress({
			...applicationAddress,
			propertyAddress: {
				city: data.City,
				state: data.State,
				postalCode: data.ZipCode || '',
				address1: data.Address1 || '',
				address2: data.Address2 || ''
			}
		});
	};

	const onSubmit = () => {
		const errorKeys = Object.keys(errors);
		if (!errorKeys.length) {
			void handleSubmit((data: IFormICheckoutInput) => {
				data.phoneNumber = data.phoneNumber.split('-').join('');
				setPaymentData(data);
				navigate('/summary');
			})();
		}
	}

	return (
		<Box maxWidth={"lg"} sx={{ mt: 5 }}>
			<Typography variant={"h3"} align={"center"}>Check Out</Typography>
			<Grid container spacing={4} direction={isMedium ? "column-reverse" : 'row'}>
				<FormProvider {...methods}>
					<Grid item xs={12} md={8} lg={8}>
						<CheckoutForm isError={isError}/>
						<CheckoutErrorsInfo/>
					</Grid>
				</FormProvider>
				<Grid item xs={12} md={4} lg={4}>
					<CoverageOverview/>
				</Grid>
			</Grid>
			<Grid container mt={4}>
				<Grid item xs={12} md={8} lg={8}>
					<Grid container justifyContent={isSmall ? "center" : "end"}>
						<Grid item xs={6} md={6} lg={5}>
							<StyledButton
								variant="outlined"
								onClick={onSubmit}
							>
								MAKE PAYMENT
							</StyledButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}
export default CheckoutPage
