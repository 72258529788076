import React from 'react';
import { useRoutes } from 'react-router-dom';
import { routeConfig } from 'app/providers/router/config/routerConfig';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormDataProvider from "../../formValues/ui/FormDataProvider";
import ApplicationFormProvider from "../../applicationForm/ui/ApplicationFormProvider";
import ScrollToTop from "shared/ui/ScrollToTop/ScrollToTop";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import NavigationProvider from './NavigationProvider';
import { Box, Container } from '@mui/material';

const client = new QueryClient(
	{
		defaultOptions: {
			queries: {
				gcTime: Infinity
			}
		}
	}
)
const AppRouter = () => {
	const elements = useRoutes(Object.values(routeConfig))
	return (
		<QueryClientProvider client={client}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<NavigationProvider>
					<ApplicationFormProvider>
						<FormDataProvider>
							<Container maxWidth={"lg"} sx={{backgroundColor: '#FFF'}}>{elements}</Container>
							<ScrollToTop/>
						</FormDataProvider>
					</ApplicationFormProvider>
				</NavigationProvider>
			</LocalizationProvider>
		</QueryClientProvider>
	)
}

export default AppRouter
