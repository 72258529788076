/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useContext, useState, useEffect } from 'react'
import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import RadioButton from "shared/ui/RadioButton/RadioButton";
import { ReactComponent as Next } from "assets/images/navigate_next.svg";
import { AdditionalItem, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from "shared/ui/Accordion";
import { IFormInput } from 'app/providers/formValues/ui/FormDataProvider';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { useJourneyResultFormQuery } from 'entities/ApplicationForm/hooks/queries';
import getUserData, { adaptParams } from 'shared/utils/getUserData';

const Deductible = () => {
	const { setInitialPayloadData, defaultQuery, initialPayload, policyState } = useContext(FormDataContext);
	const query = useJourneyResultFormQuery();
	const data = query && adaptParams(query.coverage_info.parameters);
	const deductibleAssurant = data?.deductible;

	const deductibleChosen = initialPayload.coverage.parameters.find((item) => item.name === 'deductible');
	const [expanded, setExpanded] = useState<string | boolean>(false);
	const [deductibleOptions, setDeductibleOptions] = useState<any>();
	const { control, setValue, getValues } = useFormContext<IFormInput>()

	if (deductibleChosen?.value && getValues('deductible') !== deductibleChosen.value) {
		setValue('deductible', String(deductibleChosen.value));
	}

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
		setValue('deductible', value)
		setInitialPayloadData((prevState) => {
			if(!prevState) return prevState;
			const newParametersData = prevState.coverage.parameters.map((item) => {
				if(item.name === 'deductible') {
					return {...item, value}
				}
				return item
			})
			return {
				...prevState,
				coverage: {
					parameters: newParametersData
				}
			}
		})
	}

	useEffect(() => {
		if (defaultQuery) {
			let deductible = defaultQuery.coveragesCue.parameters.find((item) => item.name === 'deductibleOptions');
			if (deductible && Array.isArray(deductible?.value) && deductibleAssurant) {
				deductible.value = deductible?.value?.filter((item: any) => {
					return item >= deductibleAssurant.substring(1);
				})
			}
			setDeductibleOptions(deductible);
		}
	}, [defaultQuery]);

	return (
		<Box sx={{ mb: 3, mt: 3 }}>
			<Box sx={{ mb: 3 }}>
				<Typography variant="subtitle2" sx={{ mb: 2 }}>Deductible</Typography>
				<Typography color="darkGrey.secondary">
					A deductible is a lump sum you have to pay upfront, but with us, we'll subtract it from your claim
					payout.
					A higher deductible will lower your monthly payment, but also means you'll receive less for a
					covered loss.
					For example, if you choose a $250 deductible and you have a table worth $800, we'll give you $550
					back.</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item md={12} sm={12} xs={12}>
					<Controller
						name="deductible"
						control={control}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									onChange={handleRadioChange}
									aria-label="icon-radio"
								>
									<Grid container spacing={3}>
										{deductibleOptions &&
											Array.isArray(deductibleOptions.value) &&
											deductibleOptions.value.length > 0 &&
											deductibleOptions.value.map((item: number) => {
												return (
													<Grid item md={4} sm={4} xs={12} key={item}>
														<RadioButton
															checked={field.value === item.toString()}
															value={item.toString()} label={`$${item}`}
															popular={item === 250}
														/>
													</Grid>
												)
											})
										}
									</Grid>
								</RadioGroup>
							)
						}}/>
				</Grid>
				{policyState === "FL" && (
					<Grid item md={6} xs={12} sm={12}>
						<StyledAccordion expanded={expanded === 'hurricane'} onChange={handleChange('hurricane')}>
							<StyledAccordionSummary aria-controls="hurricane-content" id="hurricane-header" expandIcon={<Next/>}>
								<Typography variant="subtitle2">HURRICANE DEDUCTIBLE</Typography>
								<AdditionalItem>
									<Typography variant="subtitle2">$1,000</Typography>
								</AdditionalItem>
							</StyledAccordionSummary>
							<StyledAccordionDetails>
								<Typography variant="body1">
									The deductible is what we'll subtract from your pay out should you have a Hurricane loss
								</Typography>
							</StyledAccordionDetails>
						</StyledAccordion>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

export default Deductible
