import React, { useContext, useMemo, useEffect } from 'react'
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Wrapper } from "shared/ui/Wrapper/Wrapper";
import { ReactComponent as Next } from "assets/images/navigate_next.svg";
import { ReactComponent as Plus } from "assets/images/plus.svg";
import {
	AdditionalItem,
	AdditionalItemIcon,
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary
} from 'shared/ui/Accordion';
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import CheckIcon from '@mui/icons-material/Check';

const AdditionalProtection = () => {
	const {
		protections,
		setProtections,
		setInitialPayloadData,
		defaultQuery,
		initialPayload,
		query: queryState,
		schemaAddress
	} = useContext(FormDataContext);

	const isSmall = useMediaQuery('(max-width:600px)')
	const hasCoverages = useMemo(() => {
		if (!defaultQuery) return { hasIdFraud: 'N', hasWaterBackup: 'Y', hasEarthquake: 'N', hasReplacementCost: 'N' }
		return defaultQuery?.coveragesCue.parameters.reduce<Record<string, string | number[] | null>>((accum, item) => {
			accum[item.name] = item.value
			return accum
		}, {})
	}, [defaultQuery]);
	const [expanded, setExpanded] = React.useState<string | boolean>('');
	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};
	const changeProtectionStatus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
		event.stopPropagation()
		if (name === 'replacementCost') return
		setProtections(prevState => {
			return { ...prevState, [name]: prevState[name] === 'Y' ? 'N' : 'Y' }
		})
		setInitialPayloadData((prevState) => {
			const newParametersData = prevState.coverage.parameters.map((item) => {
				if (item.name === name) {
					return { ...item, value: protections[name] as string === 'Y' ? 'N' : 'Y' }
				}
				return item
			})
			return {
				...prevState,
				coverage: {
					parameters: newParametersData
				}
			}
		})
	}
	// useEffect(() => {
	// 	if (initialPayload.coverage.parameters) {
	// 		let mappedProtections = { ...protections };
	// 		console.log('mappedProtections: ', mappedProtections);
	// 		["earthquake", "identityFraud", "waterBackup", "replacementCost", "hasWaterDamageLiability", 'floodIncluded'].map((item: string) => {
	// 			const isIncluded = initialPayload.coverage.parameters.find((param) => param.name === item)?.value;
	// 			mappedProtections = { ...mappedProtections, ...(isIncluded && {[item]: String(isIncluded)}) };
	// 		});
	// 		setProtections(mappedProtections);
	// 	}
	// }, [initialPayload.coverage.parameters]);
	const waterBackup = queryState?.coverageDetails.parameters.filter((item) => item.name === "waterBackup")[0];
	useEffect(() => {
		if (waterBackup) {
			setProtections(prevState => {
				return { ...prevState, ["waterBackup"]: waterBackup.value || 'N' }
			})
		}
	}, [waterBackup]);
	return (
		<Box sx={{ mb: 4 }}>
			<Box sx={{ mb: 3 }}>
				<Typography variant="subtitle2" sx={{ mb: 2 }}>Add Additional Protection</Typography>
			</Box>
			<Wrapper>
				<StyledAccordion expanded={expanded === 'replacement'} onChange={handleChange('replacement')}>
					<StyledAccordionSummary
						aria-controls="replacement-content"
						id="replacement-header"
						expandIcon={<Next/>}>
						<Typography variant="subtitle2">Replacement cost</Typography>
						<AdditionalItem
							onClick={(event) => changeProtectionStatus(event, 'replacementCost')}
							disabled={hasCoverages?.hasReplacementCost === 'N'}>
							{protections['replacementCost'] === 'N' ? (
								<>
									{!isSmall ? 'Add' : null}
									<AdditionalItemIcon
										sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
								</>
							) : (
								<>
									<AdditionalItemIcon
										sx={{ backgroundColor: "orange.main" }}><CheckIcon/></AdditionalItemIcon>
									{!isSmall && <span style={{ marginLeft: '5px' }}>Included</span>}
								</>
							)
							}
						</AdditionalItem>
					</StyledAccordionSummary>
					<StyledAccordionDetails>
						<Typography variant="body1" color="darkGrey.secondary">
							Coverage includes reimbursement for the actual cost of replacing belongings.
						</Typography>
					</StyledAccordionDetails>
				</StyledAccordion>
				{protections.floodIncluded && <StyledAccordion expanded={expanded === 'floodIncluded'} onChange={handleChange('floodIncluded')}>
					<StyledAccordionSummary aria-controls="floodIncluded-content" id="floodIncluded-header" expandIcon={<Next/>}>
						<Typography variant="subtitle2">Flood</Typography>
						<AdditionalItem
							disabled={true}
							onClick={(event) => changeProtectionStatus(event, 'floodIncluded')}
						>
							{protections['floodIncluded'] === 'N' ? (
								<>
									{!isSmall ? 'Add' : null}
									<AdditionalItemIcon sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
								</>
							) : (
								<>
									<AdditionalItemIcon sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
									{!isSmall && <span style={{ marginLeft: '5px' }}>Included</span>}
								</>
							)}
						</AdditionalItem>
					</StyledAccordionSummary>
					<StyledAccordionDetails>
						<Typography variant="body1" color="darkGrey.secondary">
							Coverage includes up to $2,500 for your belongings damaged by a flood,
							up to $1,000 for additional living expenses if you need to stay somewhere else,
							and up to $100 for food spoiled during extended power outages.
						</Typography>
					</StyledAccordionDetails>
				</StyledAccordion>
				}
				{hasCoverages?.hasWaterBackup === 'Y' && (
					<StyledAccordion expanded={expanded === 'waterSewer'} onChange={handleChange('waterSewer')}>
						<StyledAccordionSummary aria-controls="waterSewer-content" id="waterSewer-header" expandIcon={<Next/>}>
							<Typography variant="subtitle2">Water sewer backup</Typography>
							<AdditionalItem
								onClick={(event) => changeProtectionStatus(event, 'waterBackup')}
							>
								{protections['waterBackup'] === 'N' ? (
									<>
										{!isSmall ? 'Add' : null}
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
									</>
								) : (
									<>
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
										{!isSmall && <span style={{ marginLeft: '5px' }}>Added</span>}
									</>
								)}
							</AdditionalItem>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<Typography variant="body1" color="darkGrey.secondary">
								{schemaAddress.State === 'NC'
									? `Coverage includes up to $5,000 in damages if a sewer or drain backs up into your home.  
									A $350 deductible applies`
									: `Coverage includes up to $2,500 in damages if a sewer or drain backs up into your home. 
									A $250 deductible applies.` }
							</Typography>
						</StyledAccordionDetails>
					</StyledAccordion>
				)}
				{hasCoverages?.hasPetDamage === 'Y' && (
					<StyledAccordion expanded={expanded === 'petDamage'} onChange={handleChange('petDamage')}>
						<StyledAccordionSummary aria-controls="petDamager-content" id="petDamage-header" expandIcon={<Next/>}>
							<Typography variant="subtitle2">Pet Damage</Typography>
							<AdditionalItem
								onClick={(event) => changeProtectionStatus(event, 'petDamage')}
							>
								{protections['petDamage'] === 'N' ? (
									<>
										{!isSmall ? 'Add' : null}
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
									</>
								) : (
									<>
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
										{!isSmall && <span style={{ marginLeft: '5px' }}>Added</span>}
									</>
								)}
							</AdditionalItem>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<Typography variant="body1" color="darkGrey.secondary">
								Pet Damage – Coverage includes up to $500 to repair damages to your home caused by pets.
							</Typography>
						</StyledAccordionDetails>
					</StyledAccordion>
				)}
				{queryState?.policyHolderDetails.insured.address.state === 'CA' &&
					<StyledAccordion expanded={expanded === 'earthquake'} onChange={handleChange('earthquake')}>
						<StyledAccordionSummary aria-controls="earthquake-content" id="earthquake-header" expandIcon={<Next/>}>
							<Typography variant="subtitle2">Earthquake</Typography>
							<AdditionalItem
								onClick={(event) => changeProtectionStatus(event, 'earthquake')}
								disabled={hasCoverages?.hasEarthquake === 'N'}>
								{protections['earthquake'] === 'N' ? (
									<>
										{!isSmall ? 'Add' : null}
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
									</>
								) : (
									<>
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
										{!isSmall && <span style={{ marginLeft: '5px' }}>Added</span>}
									</>
								)}
							</AdditionalItem>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<Typography variant="body1" color="darkGrey.secondary">
								Coverage includes up to the amount you chose for your belongings and additional living
								expenses if you need
								to stay somewhere else. A deductible applies.
							</Typography>
						</StyledAccordionDetails>
					</StyledAccordion>
				}
				{hasCoverages?.hasIdFraud === 'Y' && (
					<StyledAccordion expanded={expanded === 'fraudExpense'} onChange={handleChange('fraudExpense')}>
						<StyledAccordionSummary aria-controls="fraudExpense-content" id="fraudExpense-header" expandIcon={<Next/>}>
							<Typography variant="subtitle2">Fraud Expense Protection</Typography>
							<AdditionalItem
								onClick={(event) => changeProtectionStatus(event, 'identityFraud')}
							>
								{protections['identityFraud'] === 'N' ? (
									<>
										{!isSmall ? 'Add' : null}
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
									</>
								) : (
									<>
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
										{!isSmall && <span style={{ marginLeft: '5px' }}>Added</span>}
									</>
								)}
							</AdditionalItem>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<Typography variant="body1" color="darkGrey.secondary">
								Identity fraud expense coverage includes up to $15,000 for your expenses
								if you’re a victim of identify fraud. A $100 deductible applies.
							</Typography>
						</StyledAccordionDetails>
					</StyledAccordion>
				)}
				{queryState?.policyHolderDetails.insured.address.state === 'NC' && protections.hasWaterDamageLiability &&
					<StyledAccordion expanded={expanded === 'hasWaterDamageLiability'} onChange={handleChange('hasWaterDamageLiability')}>
						<StyledAccordionSummary aria-controls="fraudExpense-content" id="fraudExpense-header" expandIcon={<Next/>}>
							<Typography variant="subtitle2">Water Damage Liability</Typography>
							<AdditionalItem
								onClick={(event) => changeProtectionStatus(event, 'hasWaterDamageLiability')}
							>
								{protections['hasWaterDamageLiability'] === 'N' ? (
									<>
										{!isSmall ? 'Add' : null}
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
									</>
								) : (
									<>
										<AdditionalItemIcon
											sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
										{!isSmall && <span style={{ marginLeft: '5px' }}>Added</span>}
									</>
								)}
							</AdditionalItem>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<Typography variant="body1" color="darkGrey.secondary">
								Covers accidental water damage to your home
								(e.g., due to tub overflows or setting off a sprinkler system) up to the liability amount chosen.
							</Typography>
						</StyledAccordionDetails>
					</StyledAccordion>
				}
			</Wrapper>
		</Box>
	)
}

export default AdditionalProtection
