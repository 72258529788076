import { $api, $apiJourney, $baseApi } from '../index';
import { AxiosResponse } from 'axios';
import { QuoteResponse } from './quoteResponseTypes';
import { QuotePayload } from './quotePayloadTypes';
import dayjs from 'dayjs';
import { CustomerResponse, CustomerUpdatePayload } from '../../config/types/customerResponse';

export const mockPayload: QuotePayload = {
	"transaction": {
		"clientId": null,
		"productType": "RI",
		"jobNumber": "000932",
		"policyEffectiveDate": dayjs().add(1, 'day').format('YYYY-MM-DD'),
	},
	"policyHolder": {
		"insured": {
			"address": {
				"address1": "123 Main Street",
				"address2": "",
				"city": "Anytown",
				"state": "TX",
				"postalCode": "73301"
			}
		}
	},
	"underWriting": {
		"questions": []
	},
	"coverage": {
		"parameters": []
	}
}
export default class Quote {
	static async getRentersQuote(id: string): Promise<AxiosResponse<CustomerResponse>> {
		return $api.get(`Customer/${id}`)
	}
	static async updateRentersQuote(data: CustomerUpdatePayload): Promise<AxiosResponse<CustomerResponse>> {
		return $apiJourney.post(`ProcessStage`, data)
	}
	static async postRentersQuote(data: QuotePayload): Promise<AxiosResponse<QuoteResponse>> {
		return $baseApi.post(``, data)
	}
}
