/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useMemo, useState } from 'react'
import { Wrapper } from "shared/ui/Wrapper/Wrapper";
import { Typography } from "@mui/material";
import { ReactComponent as Next } from "assets/images/navigate_next.svg";
import { ReactComponent as Plus } from "assets/images/plus.svg";
import RoommatesForm from "../../Forms/RoommatesForm";
import RemoveForm from "../../Forms/Remove";
import InterestedParty from "../../Forms/InterestedParty";
import {
	AdditionalItem,
	AdditionalItemIcon,
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary
} from 'shared/ui/Accordion';
import ReadMore from "./ReadMore";
import AuthorizedUserForm from "../../Forms/AuthorizedUser";
import { useFormContext } from "react-hook-form";
import CheckIcon from '@mui/icons-material/Check';
import { fullAddress } from 'widgets/CheckoutForm/helper';
import BadgeButton from 'shared/ui/BadgeButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from "@mui/material";
import { IFormICheckoutInput } from 'shared/config/types/checkout';
import { useJourneyResultFormQuery } from 'entities/ApplicationForm/hooks/queries';

interface UserProps {
	firstName: string;
	lastName: string;
	tenantGUID?: string;
}

const modalObject: Record<string, ({open, handleClose}: { open: boolean, handleClose: () => void }) => JSX.Element> = {
	roommate: RoommatesForm,
	party: InterestedParty,
	authorized: AuthorizedUserForm,
}
const AdditionalParties = () => {
	const { getValues, setValue, watch } = useFormContext<IFormICheckoutInput>();
	const query = useJourneyResultFormQuery();
	const watchInsured = watch('additionalInsured')
	const watchParty = watch('interestedParty')
	const watchRoommates = watch('roommates')
	const watchAuthorized = watch('authorizedUser')
	const [modal, setModal] = useState('');
	const [expanded, setExpanded] = React.useState<string | boolean>('panel1');
	const [openModal, setOpenModal] = useState(false);
	const [additionalInsured, setAdditionalInsured] = useState<UserProps[]>([]);
	const [authorizedUser, setAuthorizedUser] = useState('');
	const [partyName, setPartyName] = useState('');
	const [partyAddress, setPartyAddress] = useState('');
	const [propertyName, setPropertyName] = useState('');
	const [selectedRoommate, setSelectedRoommate] = useState<UserProps>();
	useEffect(() => {
		if (query) {
			const additionalInsuredMoved = query.additional_parties
			const interestedParty = query.interested_party
			if (additionalInsuredMoved && additionalInsuredMoved.length) {
				const roommates_arr: UserProps[] = additionalInsuredMoved.map((item) => {
					return {
						firstName: item.first_name,
						lastName: item.last_name,
						// tenantGUID: item.TenantGUID,
					}
				})
				setValue('roommates', roommates_arr);
				setAdditionalInsured(roommates_arr);
			}
			if (interestedParty) {
				setValue('interestedParty.name', `${interestedParty.first_name} ${interestedParty.last_name}`);
				setValue('interestedParty.streetAddress', interestedParty.address.street1);
				setValue('interestedParty.app', interestedParty.address.street2);
				setValue('interestedParty.city', interestedParty.address.city);
				setValue('interestedParty.state', interestedParty.address.state);
				setValue('interestedParty.zipcode', interestedParty.address.zip);
			}
		}
	}, [query]);

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const removeProperty = (name: string) => {
		setModal('remove');
		setPropertyName(name);
	}

	const removePropertyAction = () => {
		if (propertyName === "Authorized User") removeAuthUser();
		if (propertyName === "Roommate") removeRoommate();
	}

	const removeAuthUser = () => {
		setAuthorizedUser('');
		setValue('authorizedUser.authorizedUserFirstName', '');
		setValue('authorizedUser.authorizedUserLastName', '');
		setModal('');
	}

	const removeRoommate = () => {
		const newArr: UserProps[] = [...additionalInsured].filter(item =>
			item.firstName !== selectedRoommate?.firstName && item.lastName !== selectedRoommate?.lastName);
		setAdditionalInsured(newArr);
		setValue('additionalInsured.firstName', '');
		setValue('additionalInsured.lastName', '');
		setModal('');
	}

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	useEffect(() => {
		setValue('roommates', additionalInsured);
	}, [additionalInsured]);

	useEffect(() => {
		if(watchInsured) {
			const { firstName, lastName } = watchInsured
			if(firstName && lastName) {
				setAdditionalInsured([{ firstName, lastName }]);
			}
		}
	}, [watchInsured]);

	useEffect(() => {
		if(watchAuthorized) {
			const { authorizedUserFirstName, authorizedUserLastName } = watchAuthorized;
			if (authorizedUserFirstName && authorizedUserLastName) {
				const fullName = authorizedUserFirstName + ' ' + authorizedUserLastName;
				setAuthorizedUser(fullName);
			} else setAuthorizedUser('');
		}
	}, [watchAuthorized]);

	useEffect(() => {
		if(watchParty) {
			const { name, city, state, app, zipcode, streetAddress } = watchParty
			const address = fullAddress({address1: streetAddress, address2: app, city, state, postalCode: zipcode});
			if (name && address) {
				setPartyName(name);
				setPartyAddress(address);
			} else {
				setPartyName('');
				setPartyAddress('');
			}
		}
	}, [watchParty]);

	useEffect(() => {
		if(watchRoommates?.length && !additionalInsured.length) {
			setAdditionalInsured(watchRoommates);
		}
	}, [watchRoommates]);

	const processUserInput = () => {
		if (modal === "authorized") {
			const firstName: string = getValues("authorizedUser.authorizedUserFirstName");
			const lastName: string = getValues("authorizedUser.authorizedUserLastName");

			if (firstName && lastName) {
				const fullName = firstName + ' ' + lastName;
				setAuthorizedUser(fullName);
			} else setAuthorizedUser('');
		}
		if (modal === "roommate") {

			const firstName: string = getValues("additionalInsured.firstName");
			const lastName: string = getValues("additionalInsured.lastName");

			if (firstName && lastName) {
				setAdditionalInsured([...additionalInsured, { firstName, lastName}]);
				setValue('additionalInsured.firstName', '');
				setValue('additionalInsured.lastName', '');
			}
		}
		if (modal === "party") {

			const fullName: string = getValues("interestedParty.name");
			const address1: string = getValues("interestedParty.streetAddress");
			const address2: string = getValues("interestedParty.app");
			const city: string = getValues("interestedParty.city");
			const state: string = getValues("interestedParty.state");
			const postalCode: string = getValues("interestedParty.zipcode");

			const address = fullAddress({address1, address2, city, state, postalCode});

			if (fullName && address) {
				setPartyName(fullName);
				setPartyAddress(address);
			} else {
				setPartyName('');
				setPartyAddress('');
			}
		}
		setModal('');
	};

	const modalComponent = useMemo(() => {
		const NewComponent = modalObject[modal]
		if (NewComponent) {
			return <NewComponent open={!!modalObject[modal]} handleClose={processUserInput}/>
		}
		return null
	}, [modal]);

	const handleBadgeChange = (item: string, e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setModal(item)
	}

	const handleDeleteRoommate = (item: UserProps) => {
		removeProperty('Roommate');
		setSelectedRoommate(item);
	};

	return (
		<Wrapper>
			<Typography variant="h4">Additional Parties - Free</Typography>
			<StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<StyledAccordionSummary aria-controls="panel1-content" id="panel1-header" expandIcon={<Next/>}>
					<Typography variant="subtitle2">Roommate</Typography>
					<AdditionalItem onClick={(event) => handleBadgeChange('roommate', event)}>
						<>
							{!additionalInsured.length ? 'Add' : 'Added'}
							<AdditionalItemIcon
								sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
						</>
					</AdditionalItem>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<Typography variant="subtitle2" sx={{fontSize: '11px', lineHeight: '15px'}}>
						You can add one roommate to your policy.
						(Your spouse, domestic partner or dependent are automatically covered and don't need to be added)
					</Typography>
					{additionalInsured?.map((item: UserProps) => (
						<BadgeButton
							key={`${item.firstName} ${item.lastName}`}
							name={`${item.firstName} ${item.lastName}`}
							onDelete={() => handleDeleteRoommate(item)}
						/>
					))}
				</StyledAccordionDetails>
			</StyledAccordion>
			<StyledAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<StyledAccordionSummary aria-controls="panel2-content" id="panel2-header" expandIcon={<Next/>}>
					<Typography variant="subtitle2">Interested party</Typography>
					<AdditionalItem onClick={(event) => handleBadgeChange('party', event)}>
						{!partyName && !partyAddress ? (
							<>
								Add
								<AdditionalItemIcon
									sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
							</>
						) : (
							<>
								<AdditionalItemIcon
									sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
								{<span style={{ marginLeft: '5px' }}>Added</span>}
							</>
						)}
					</AdditionalItem>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<Typography variant="subtitle2" sx={{fontSize: '11px', lineHeight: '15px'}}>
						This is typically your landlord or property manager. Put down their information and we'll
						automatically notify them that you've got coverage.
					</Typography>
					<Typography
						variant="subtitle2"
						sx={{fontSize: '11px', lineHeight: '15px', cursor: 'pointer', textDecoration: 'underline'}}
						onClick={() => setOpenModal(true)}>
						Read more
					</Typography>
					{partyName && partyAddress && (
						<>
							<Typography variant="subtitle2">{partyName}</Typography>
							<div style={{ height: '25px' }}>
								<Typography variant="subtitle2" sx={{ float: 'left' }}>{partyAddress}</Typography>
								<IconButton onClick={() => setModal('party')} sx={{ float: 'right', padding: '0', backgroundColor: 'transparent' }}>
									<ModeEditIcon />
									<Typography variant="subtitle2">Edit</Typography>
								</IconButton>
							</div>
						</>
					)}
				</StyledAccordionDetails>
			</StyledAccordion>
			<StyledAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
				<StyledAccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<Next/>}>
					<Typography variant="subtitle2">Authorized user</Typography>
					<AdditionalItem onClick={() => setModal('authorized')}>
						{!authorizedUser ? (
							<>
								Add
								<AdditionalItemIcon
									sx={{ backgroundColor: "text.secondary" }}><Plus/></AdditionalItemIcon>
							</>
						) : (
							<>
								<AdditionalItemIcon
									sx={{ backgroundColor: "text.secondary" }}><CheckIcon/></AdditionalItemIcon>
								{<span style={{ marginLeft: '5px' }}>Added</span>}
							</>
						)}
					</AdditionalItem>
				</StyledAccordionSummary>
				<StyledAccordionDetails>
					<Typography variant="subtitle2" sx={{fontSize: '11px', lineHeight: '15px'}}>
						If you need someone else to pay your bill or manage your policy, add them here.
					</Typography>
					{authorizedUser && (
						<BadgeButton
							name={authorizedUser}
							onClick={() => setModal('authorized')}
							onDelete={() => removeProperty('Authorized User')}
						/>
					)}
				</StyledAccordionDetails>
			</StyledAccordion>
			{modal && modalObject[modal] && modalComponent}
			{modal === 'remove' && (
				<RemoveForm
					propertyName={propertyName}
					open={modal === 'remove'}
					handleClose={() => setModal('')}
					handleRemove={removePropertyAction}
				/>
			)}
			<ReadMore open={openModal} handleClose={handleCloseModal}/>
		</Wrapper>
	)
}

export default AdditionalParties
